import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn, doesUserHaveRole } from "../../components/accountManagement";
import { SideBarOptions, adminMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { CrudTable } from "../../components/crudTable";

const uri = "/admin/comments/";

const AdminCommentsPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  const CommentRow = ({ data }) => {
    return (
      <>
        <td>{data.id}</td>
        <td>{data.status}</td>
        <td>{data.comment_for_name}</td>
        <td>{data.comment_text}</td>
        <td>{data.new_comment_text}</td>
        <td>{data.rating}</td>
        <td>{data.display_name}</td>
        <td>
          {data.created_date}
          {data.created_date.substring(0, 19) === data.modified_date.substring(0, 19) ? (
            <></>
          ) : (
            <>
              <br />
              {data.modified_date}
            </>
          )}
        </td>
      </>
    );
  };

  return (
    <Layout pageInfo={{ pageName: "admin-comments" }} sideBarOptions={SideBarOptions(adminMenuLinks, uri, "Admin")}>
      <Seo title="Admin Comments" keywords={[`admin`]} description="Admin Comments" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={adminMenuLinks} currentLink={uri} menuTitle="Admin" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Admin</Breadcrumb.Item>
            <Breadcrumb.Item active>Comments</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      {isUserSignedIn(userData) && doesUserHaveRole(userData, "COMMENT_ADMIN") ? (
        <CrudTable
          url="/comments"
          urlParams="admin=true&comment_for=ilir-recipe&search_text_options=FULL"
          customFilters={[
            { name: "search_text", param_name: "search_text", default: "" },
            { name: "review_status", param_name: "statuses", default: "PENDING" },
          ]}
          colHeaders={[
            "Id",
            "Status",
            "Recipe",
            "Approved Comment",
            "Pending Comment",
            "Rating",
            "Display Name",
            "Created Date/<br/>Modified Date",
          ]}
          rowComponent={(data) => {
            return <CommentRow data={data} />;
          }}
          actionsAllowed={["approve-comment", "delete-comment", "edit"]}
          editColumns={[
            {
              name: "comment_text",
              label: "Comment Text",
              type: "textarea",
              rows: "4",
            },
            {
              name: "new_comment_text",
              label: "New Comment Text",
              type: "textarea",
              rows: "4",
            },
          ]}
        />
      ) : (
        <>Not logged in {navigate("/account/signin/")}</>
      )}
    </Layout>
  );
};

export default AdminCommentsPage;
